import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as FlameIcon } from '../../../svgs/nav/hostjane-footer-logo.svg';

const TwitterUpdatesWrapper = styled.section`
	&.hostjane-main__twitterUpdates {
		max-width: 614px;
		margin: auto;
		margin-bottom: 2em;
		background: white;
		margin-top: 40px;
		border-radius: 4px;
		box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
			0 5px 15px rgba(0, 0, 0, 0.07);
		@media screen and (max-width: 767px) {
			width: 87.5%;
		}
		.hostjane-main__twitterUpdates__tweets {
			.hostjane-main__twitterUpdates__tweets__inner-content {
				padding: 35px;
				.hostjane-main__twitterUpdates__tweets__inner-content__top-bar {
					margin-bottom: 25px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					h2 {
						color: #525f7f;
						font-size: 25px;
						padding-right: 10px;
					}
					a {
						svg {
							width: 40px;
							height: 30px;
						}
						text-decoration: none;
						cursor: pointer;
						display: flex;
						width: 40px;
						height: 30px;
						&:hover {
							text-decoration: none;
						}
					}
				}
				.hostjane-main__twitterUpdates__tweets__inner-content__list {
					list-style: none;
					padding-left: 0;
					.hostjane-main__twitterUpdates__tweets__inner-content__list__tweet {
						position: relative;
						min-height: 40px;
						margin-bottom: 20px;
						svg {
							width: 27px;
							height: 27px;
							position: absolute;
							border: 0;
						}
						> div {
							background: #e6ebf1;
							margin-left: 40px;
							position: relative;
							top: -1px;
							&:before {
								content: ' ';
								position: absolute;
								width: 10px;
								height: 10px;
								transform: rotate(45deg);
								left: -5px;
								top: 11px;
								background: #e6ebf1;
							}
							.text {
								padding: 7px 12px;
								font-size: 16px;
								line-height: 21px;
								color: #525f7f;
								margin: 0;
								a {
									display: inherit;
								}
							}
						}
						span {
							margin: 0;
							display: block;
							margin-left: 52px;
							padding-top: 7px;
							text-align: left;
							font-size: 11px;
							color: #aab7c4;
						}
					}

					.loader {
						text-align: center;

						.lds-ellipsis {
							display: inline-block;
							position: relative;
							width: 80px;
							height: 80px;
						}
						.lds-ellipsis div {
							position: absolute;
							top: 33px;
							width: 13px;
							height: 13px;
							border-radius: 50%;
							background: #e41937;
							animation-timing-function: cubic-bezier(0, 1, 1, 0);
						}
						.lds-ellipsis div:nth-child(1) {
							left: 8px;
							animation: lds-ellipsis1 0.6s infinite;
						}
						.lds-ellipsis div:nth-child(2) {
							left: 8px;
							animation: lds-ellipsis2 0.6s infinite;
						}
						.lds-ellipsis div:nth-child(3) {
							left: 32px;
							animation: lds-ellipsis2 0.6s infinite;
						}
						.lds-ellipsis div:nth-child(4) {
							left: 56px;
							animation: lds-ellipsis3 0.6s infinite;
						}
						@keyframes lds-ellipsis1 {
							0% {
								transform: scale(0);
							}
							100% {
								transform: scale(1);
							}
						}
						@keyframes lds-ellipsis3 {
							0% {
								transform: scale(1);
							}
							100% {
								transform: scale(0);
							}
						}
						@keyframes lds-ellipsis2 {
							0% {
								transform: translate(0, 0);
							}
							100% {
								transform: translate(24px, 0);
							}
						}
					}
				}
			}
			.hostjane-main__twitterUpdates__tweets__tweet-link {
				background: #f6f9fc;
				padding: 25px 36px;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				font-size: 16px;
				color: #6b7c93;
				a {
					color: #6772e5;
					text-decoration: none;
					&:hover {
						color: #32325d;
						text-decoration: none;
					}
				}
			}
		}
	}
`;

const TwitterUpdates = ({ twitterIcon }) => {
	const [tweets, setTweets] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const fetchTweets = async () => {
		const response = await axios.post(
			' https://twitter-hostjane-api.herokuapp.com/tweets',
			{
				screen_name: 'hostjanesupport',
				count: 6,
			}
		);
		setTweets(response.data.tweets);
		setIsLoading(false);
	};
	useEffect(() => {
		fetchTweets();
	}, []);
	return (
		<TwitterUpdatesWrapper className="hostjane-main__twitterUpdates">
			<div className="hostjane-main__twitterUpdates__tweets">
				<div className="hostjane-main__twitterUpdates__tweets__inner-content">
					<div className="hostjane-main__twitterUpdates__tweets__inner-content__top-bar">
						<h2>Twitter updates</h2>
						<a
							href="https://www.x.com/hostjanesupport"
							target="__blank"
						>
							{twitterIcon}
						</a>
					</div>
					<ul className="hostjane-main__twitterUpdates__tweets__inner-content__list">
						{!isLoading ? (
							tweets.map(
								(
									{ full_text: tweet, created_at: date },
									index
								) => {
									return (
										<li
											key={index}
											className="hostjane-main__twitterUpdates__tweets__inner-content__list__tweet"
										>
											<FlameIcon />
											<div>
												<div
													className="text"
													dangerouslySetInnerHTML={{
														__html: tweet,
													}}
												/>
											</div>
											<span>
												{`${new Date(
													date
												).toDateString()}, ${new Date(
													date
												).toLocaleTimeString()}`}
											</span>
										</li>
									);
								}
							)
						) : (
							<div className="loader">
								<div className="lds-ellipsis">
									<div />
									<div />
									<div />
									<div />
								</div>
							</div>
						)}
					</ul>
				</div>

				<div className="hostjane-main__twitterUpdates__tweets__tweet-link">
					Follow us at{' '}
					<a
						href="https://x.com/hostjanesupport"
						target="__blank"
					>
						@HostJaneSupport
					</a>{' '}
					for latest updates!
				</div>
			</div>
		</TwitterUpdatesWrapper>
	);
};

export default TwitterUpdates;
