import React, { useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import useWindowSize from '../../../utils/useWindowSize';

const SystemStatusWrapper = styled.section`
	&.hostjane-main__systemStatus {
		margin-left: auto;
		margin-right: auto;
		max-width: 980px;
		@media screen and (max-width: 1068px) {
			max-width: 692px;
		}
		@media screen and (max-width: 876px) {
			width: 87.5%;
		}
		.hostjane-main__systemStatus__title {
			padding-top: 20px;
			padding-bottom: 25px;
			text-align: center;
			font-size: 40px;
			line-height: 1.04167;
			font-weight: 600;
			letter-spacing: 0.006em;
			margin: 0;
			color: #333333;
		}
		.hostjane-main__systemStatus__status-bar {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-bottom: 1em;
			text-align: right;
			font-size: 16px;
			line-height: 1.5;
			font-weight: 400;
			letter-spacing: -0.01em;
			.hostjane-main__systemStatus____status-bar__online-status {
				background-color: #28c940;
				border-radius: 50%;
				margin-right: 5px;
				height: 16px;
				width: 16px;
				background-size: 16px 16px;
				vertical-align: middle;
				display: inline-block;
				position: relative;
			}
			p {
				color: #333333;
				font-size: 16px;
				line-height: 1.5;
				font-weight: 400;
				letter-spacing: -0.01em;
				margin: 0;
			}
		}

		.hostjane-main__systemStatus__table {
			margin-bottom: 1.5em;
			width: 100%;
			border-collapse: collapse;
			border-spacing: 0;
			.hostjane-main__systemStatus__table__tr {
				width: 100%;
				.hostjane-main__systemStatus__table__td {
					width: 33%;
					padding: 5px 10px;
					margin: 0;
					border-top: 1px solid #d3d3d3;
					border-bottom: 1px solid #d3d3d3;
					border-right: 1px solid #d3d3d3;
					&:last-of-type {
						border-right: none;
					}
					.hostjane-main__systemStatus__table__td__wrapper {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.hostjane-main__systemStatus__table__td__wrapper__status-bar {
							display: flex;
							align-items: center;
							.hostjane-main__systemStatus__table__td__wrapper__status-bar__online-status {
								background-color: #28c940;
								border-radius: 50%;
								margin-right: 10px;
								height: 16px;
								width: 16px;
								position: relative;
							}
							p {
								color: #333333;
								margin: 0;
								font-size: 16px;
								line-height: 1.5;
								font-weight: 400;
								letter-spacing: -0.01em;
							}
						}
					}
					.hostjane-main__systemStatus__table__td__url {
						width: 13px;
						padding-left: 5px;
						a {
							background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bD0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSI3MTEgOTAgMTggMTgiIHdpZHRoPSIxOHB0IiBoZWlnaHQ9IjE4cHQiIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyI+CiAgPG1ldGFkYXRhPiBQcm9kdWNlZCBieSBPbW5pR3JhZmZsZSA3LjQuMiAKICAgIDxkYzpkYXRlPjIwMTgtMDMtMDEgMDc6MDE6MjEgKzAwMDA8L2RjOmRhdGU+CiAgPC9tZXRhZGF0YT4KICA8ZGVmcy8+CiAgPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utb3BhY2l0eT0iMSIgc3Ryb2tlLWRhc2hhcnJheT0ibm9uZSIgZmlsbD0ibm9uZSIgZmlsbC1vcGFjaXR5PSIxIj4KICAgIDx0aXRsZT5DYW52YXMgMTwvdGl0bGU+CiAgICA8cmVjdCBmaWxsPSJ3aGl0ZSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4Ii8+CiAgICA8Zz4KICAgICAgPHRpdGxlPkxheWVyIDE8L3RpdGxlPgogICAgICA8cGF0aCBkPSJNIDcyNSAxMDYuMjUgTCA3MTIuNzUgMTA2LjI1IEwgNzEyLjc1IDk0IEwgNzE4Ljg3NSA5NCBMIDcxOC44NzUgOTIuMjUgTCA3MTIuNzUgOTIuMjUgQyA3MTEuNzc4NzUgOTIuMjUgNzExIDkzLjAzNzUgNzExIDk0IEwgNzExIDEwNi4yNSBDIDcxMSAxMDcuMjEyNSA3MTEuNzc4NzUgMTA4IDcxMi43NSAxMDggTCA3MjUgMTA4IEMgNzI1Ljk2MjUgMTA4IDcyNi43NSAxMDcuMjEyNSA3MjYuNzUgMTA2LjI1IEwgNzI2Ljc1IDEwMC4xMjUgTCA3MjUgMTAwLjEyNSBMIDcyNSAxMDYuMjUgWiIgZmlsbD0iIzAwNzBjOSIvPgogICAgICA8cGF0aCBkPSJNIDcyMi42NTU2IDkwIEwgNzIyLjY1NTYgOTEuODEyNjkgTCA3MjUuOTA5NCA5MS44MTI2OSBMIDcxNyAxMDAuNzIyMDUgTCA3MTguMjc3OTUgMTAyIEwgNzI3LjE4NzMgOTMuMDkwNjMgTCA3MjcuMTg3MyA5Ni4zNDQ0MSBMIDcyOSA5Ni4zNDQ0MSBMIDcyOSA5MCBMIDcyMi42NTU2IDkwIFoiIGZpbGw9IiMwMDcwYzkiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=);
							background-repeat: no-repeat;
							height: 13px;
							width: 13px;
							position: relative;
							background-size: 13px 13px;
							cursor: pointer;
							display: block;
						}
					}
				}
			}
		}

		.hostjane-main__systemStatus__mobile-container {
			border-bottom: 1px solid #d3d3d3;
			border-top: 1px solid #d3d3d3;

			margin-bottom: 1.5em;
			padding: 10px 5px;
			cursor: pointer;

			.hostjane-main__systemStatus__mobile-container__main {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.hostjane-main__systemStatus__mobile-container__main__toggle-bar {
					display: flex;
					align-items: center;
					.hostjane-main__systemStatus__mobile-container__main__toggle-bar__status-indicator {
						background-color: #28c940;
						border-radius: 50%;
						margin-right: 10px;
						height: 16px;
						width: 16px;
						position: relative;
						margin-right: 10px;
					}
					.hostjane-main__systemStatus__mobile-container__main__toggle-bar__content {
						font-size: 16px;
						line-height: 1.52947;
						font-weight: 400;
						letter-spacing: -0.021em;
						margin: 0;
					}
				}
				.hostjane-main__systemStatus__mobile-container__main__toggle {
					background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGw9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iLTIgLTIgMjUgMjQiIHdpZHRoPSIyNXB0IiBoZWlnaHQ9IjJwYyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIj48bWV0YWRhdGE+IFByb2R1Y2VkIGJ5IE9tbmlHcmFmZmxlIDYuNi4xIDxkYzpkYXRlPjIwMTYtMTItMjAgMjM6NDI6MzQgKzAwMDA8L2RjOmRhdGU+PC9tZXRhZGF0YT48ZGVmcy8+PGcgc3Ryb2tlPSJub25lIiBzdHJva2Utb3BhY2l0eT0iMSIgc3Ryb2tlLWRhc2hhcnJheT0ibm9uZSIgZmlsbD0ibm9uZSIgZmlsbC1vcGFjaXR5PSIxIj48dGl0bGU+aWNvbl81MTJ4NTEyPC90aXRsZT48Zz48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PGVsbGlwc2UgY3g9IjEwLjUiIGN5PSIxMCIgcng9IjEwLjUwMDAxNyIgcnk9IjEwLjAwMDAxNiIgZmlsbD0id2hpdGUiLz48ZWxsaXBzZSBjeD0iMTAuNSIgY3k9IjEwIiByeD0iMTAuNTAwMDE3IiByeT0iMTAuMDAwMDE2IiBzdHJva2U9IiMxYjhjY2EiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48cmVjdCB4PSIxMCIgeT0iNCIgd2lkdGg9IjEiIGhlaWdodD0iMTIiIGZpbGw9IiNiZmJmYmYiLz48cmVjdCB4PSIxMCIgeT0iNCIgd2lkdGg9IjEiIGhlaWdodD0iMTIiIHN0cm9rZT0iIzFiOGNjYSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEiLz48cmVjdCB4PSI0LjUiIHk9IjkuNSIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEiIGZpbGw9IiNiZmJmYmYiLz48cmVjdCB4PSI0LjUiIHk9IjkuNSIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEiIHN0cm9rZT0iIzFiOGNjYSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEiLz48L2c+PC9nPjwvc3ZnPg==);
					background-repeat: no-repeat;
					height: 21px;
					width: 20px;
					position: relative;
					background-size: 21px 20px;
					&.active {
						background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGw9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iLTIgLTIgMjUgMjQiIHdpZHRoPSIyNXB0IiBoZWlnaHQ9IjJwYyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIj48bWV0YWRhdGE+IFByb2R1Y2VkIGJ5IE9tbmlHcmFmZmxlIDYuNi4xIDxkYzpkYXRlPjIwMTYtMTItMjEgMDU6NTM6NTAgKzAwMDA8L2RjOmRhdGU+PC9tZXRhZGF0YT48ZGVmcy8+PGcgc3Ryb2tlPSJub25lIiBzdHJva2Utb3BhY2l0eT0iMSIgc3Ryb2tlLWRhc2hhcnJheT0ibm9uZSIgZmlsbD0ibm9uZSIgZmlsbC1vcGFjaXR5PSIxIj48dGl0bGU+aWNvbl81MTJ4NTEyPC90aXRsZT48Zz48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PGNpcmNsZSBjeD0iMTAuNSIgY3k9IjEwIiByPSIxMC4wMDAwMTYiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMTAuNSIgY3k9IjEwIiByPSIxMC4wMDAwMTYiIHN0cm9rZT0iIzFiOGNjYSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIvPjxwYXRoIGQ9Ik0gMTQuMzg5MDg3IDUuNDAzODA2IEwgMTUuMDk2MTk0IDYuMTEwOTEyNyBMIDYuNjEwOTEyNyAxNC41OTYxOTQgTCA1LjkwMzgwNiAxMy44ODkwODcgWiIgZmlsbD0iI2JmYmZiZiIvPjxwYXRoIGQ9Ik0gMTQuMzg5MDg3IDUuNDAzODA2IEwgMTUuMDk2MTk0IDYuMTEwOTEyNyBMIDYuNjEwOTEyNyAxNC41OTYxOTQgTCA1LjkwMzgwNiAxMy44ODkwODcgWiIgc3Ryb2tlPSIjMWI4Y2NhIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPjxwYXRoIGQ9Ik0gMTUuMDk2MTk0IDEzLjg4OTA4NyBMIDE0LjM4OTA4NyAxNC41OTYxOTQgTCA1LjkwMzgwNiA2LjExMDkxMjcgTCA2LjYxMDkxMjcgNS40MDM4MDYgWiIgZmlsbD0iI2JmYmZiZiIvPjxwYXRoIGQ9Ik0gMTUuMDk2MTk0IDEzLjg4OTA4NyBMIDE0LjM4OTA4NyAxNC41OTYxOTQgTCA1LjkwMzgwNiA2LjExMDkxMjcgTCA2LjYxMDkxMjcgNS40MDM4MDYgWiIgc3Ryb2tlPSIjMWI4Y2NhIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPjwvZz48L2c+PC9zdmc+);
					}
				}
			}

			.hostjane-main__systemStatus__mobile-container__statuses {
				margin-left: 30px;
				padding-top: 10px;
				display: none;
				&.active {
					display: block;
				}
				.hostjane-main__systemStatus__mobile-container__statuses__status-container {
					.hostjane-main__systemStatus__mobile-container__statuses__status-container__status {
						font-size: 16px;
						line-height: 1.5;
						font-weight: 400;
						letter-spacing: -0.01em;
						padding: 5px 0px;
						border-top: 1px solid #d3d3d3;
						> span {
							.hostjane-main__systemStatus__mobile-container__statuses__status-container__status__available {
								color: #229926;
							}
						}
					}
				}
			}
		}

		.hostjane-main__systemStatus__bottom-content {
			p {
				font-size: 16px;
				line-height: 1.5;
				font-weight: 400;
				letter-spacing: -0.01em;
				margin: 0;
				&:last-of-type {
					margin-bottom: 2em;
					margin-top: 1em;
					color: #595858;
				}
			}
			a {
				font-size: 16px;
				line-height: 1.5;
				font-weight: 400;
				letter-spacing: -0.01em;
				color: #0070c9;
				margin-left: 3px;
			}

			@media only screen and (max-width: 735px) {
				p {
					color: #666;
					&:nth-of-type(2) {
						margin-top: 0.7em;
					}
				}
			}
		}
	}
`;

const statuses = [
	{
		firstLabel: 'Listings',
		secondLabel: 'Messaging',
		thirdLabel: 'Hosting',
	},
	{
		firstLabel: 'Custom Offers',
		secondLabel: 'Quote Requests',
		thirdLabel: 'File Storage',
	},
];

const SystemStatus = () => {
	const windowWidth = useWindowSize();
	const [toggleActive, setToggleActive] = useState(false);

	return (
		<SystemStatusWrapper className="hostjane-main__systemStatus">
			<h1 className="hostjane-main__systemStatus__title">
				System Status
			</h1>
			{windowWidth[0] >= 1069 && (
				<>
					<div className="hostjane-main__systemStatus__status-bar">
						<div className="hostjane-main__systemStatus____status-bar__online-status" />
						<p>Available</p>
					</div>

					<table className="hostjane-main__systemStatus__table">
						<tbody className="hostjane-main__systemStatus__table__tbody">
							{statuses.map(
								({ firstLabel, secondLabel, thirdLabel }) => {
									return (
										<tr className="hostjane-main__systemStatus__table__tr">
											<td className="hostjane-main__systemStatus__table__td">
												<div className="hostjane-main__systemStatus__table__td__wrapper">
													<div className="hostjane-main__systemStatus__table__td__wrapper__status-bar">
														<div className="hostjane-main__systemStatus__table__td__wrapper__status-bar__online-status" />
														<p>{firstLabel}</p>
													</div>
												</div>
											</td>
											<td className="hostjane-main__systemStatus__table__td">
												<div className="hostjane-main__systemStatus__table__td__wrapper">
													<div className="hostjane-main__systemStatus__table__td__wrapper__status-bar">
														<div className="hostjane-main__systemStatus__table__td__wrapper__status-bar__online-status" />
														<p>{secondLabel}</p>
													</div>
												</div>
											</td>
											<td className="hostjane-main__systemStatus__table__td">
												<div className="hostjane-main__systemStatus__table__td__wrapper">
													<div className="hostjane-main__systemStatus__table__td__wrapper__status-bar">
														<div className="hostjane-main__systemStatus__table__td__wrapper__status-bar__online-status" />
														<p>{thirdLabel}</p>
													</div>
												</div>
											</td>
										</tr>
									);
								}
							)}
						</tbody>
					</table>
				</>
			)}

			{windowWidth[0] < 1069 && (
				<div className="hostjane-main__systemStatus__mobile-container">
					<div
						tabIndex={0}
						role="button"
						className="hostjane-main__systemStatus__mobile-container__main"
						onClick={() => setToggleActive(!toggleActive)}
					>
						<div className="hostjane-main__systemStatus__mobile-container__main__toggle-bar">
							<div className="hostjane-main__systemStatus__mobile-container__main__toggle-bar__status-indicator" />
							<p className="hostjane-main__systemStatus__mobile-container__main__toggle-bar__content">
								{' '}
								All services are operating normally.
							</p>
						</div>
						<div
							className={classnames(
								'hostjane-main__systemStatus__mobile-container__main__toggle',
								toggleActive && 'active'
							)}
						/>
					</div>
					<div
						className={classnames(
							'hostjane-main__systemStatus__mobile-container__statuses',
							toggleActive && 'active'
						)}
					>
						<div className="hostjane-main__systemStatus__mobile-container__statuses__status-container">
							{statuses.map(
								({ firstLabel, secondLabel, thirdLabel }) => {
									return (
										<>
											<div className="hostjane-main__systemStatus__mobile-container__statuses__status-container__status">
												<span>
													{firstLabel} -{' '}
													<span className="hostjane-main__systemStatus__mobile-container__statuses__status-container__status__available">
														Available
													</span>
												</span>
											</div>
											<div className="hostjane-main__systemStatus__mobile-container__statuses__status-container__status">
												<span>
													{secondLabel} -{' '}
													<span className="hostjane-main__systemStatus__mobile-container__statuses__status-container__status__available">
														Available
													</span>
												</span>
											</div>
											<div className="hostjane-main__systemStatus__mobile-container__statuses__status-container__status">
												<span>
													{thirdLabel} -{' '}
													<span className="hostjane-main__systemStatus__mobile-container__statuses__status-container__status__available">
														Available
													</span>
												</span>
											</div>
										</>
									);
								}
							)}
						</div>
					</div>
				</div>
			)}
			<div className="hostjane-main__systemStatus__bottom-content">
				<p>
					If you are experiencing an issue not listed here,
					<a href="https://www.hostjane.com/marketplace/contact"> contact support.</a>
				</p>
				<p>Last updated today, {new Date().toTimeString()}</p>
			</div>
		</SystemStatusWrapper>
	);
};

export default SystemStatus;
