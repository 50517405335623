import React, { useEffect } from 'react';
import axios from 'axios';
import {
	Alert,
	backgroundColor,
	backgroundGradient,
	textColor,
} from '../../../site-data/home/alert';
import { pageDescription, pageTitle } from '../../../site-data/home/seo';
import DismissableBanner from '../../components/DismissableBanner';
import Layout from '../../components/Layout';
import MainFooter from '../../components/MainFooter';
import MainNavigation from '../../components/MainNavigation';
import SEO from '../../components/SEO';
import SystemStatus from '../../components/Status/SystemStatus';
import TwitterUpdates from '../../components/Status/TwitterUpdates';
import twitterUpdatesData from '../../../site-data/status/twitter-updates';

function StatusPage({ data, ...props }) {
	return (
		<Layout {...props} hasFooter={false} fullWidth>
			<SEO
				title={pageTitle}
				description={pageDescription}
				addPrefix={false}
			/>
			<DismissableBanner
				backgroundColor={backgroundColor}
				textColor={textColor}
				backgroundGradient={backgroundGradient}
			>
				{Alert === null ? null : <Alert />}
			</DismissableBanner>
			<MainNavigation />
			<SystemStatus />
			<TwitterUpdates twitterIcon={twitterUpdatesData.twitterIcon} />
			<MainFooter />
		</Layout>
	);
}

export default StatusPage;
